import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';
import { COMPONENT_SIZES, SizeType } from '@styles';

type Props = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  size?: SizeType; // 30, 36, 44, 48, 56
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  outLined?: boolean;
};

export const CyanButton: React.FC<Props> = ({
  className,
  text,
  onClick,
  disabled,
  fullWidth,
  startIcon,
  size = 'large',
  onMouseEnter,
  onMouseLeave,
  outLined,
}) => {
  const classes = useStyles({ size, disabled, outLined });
  return (
    <MuiButton
      className={`${className} ${classes.root}`}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={startIcon}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableRipple
    >
      {text}
    </MuiButton>
  );
};

const useStyles = makeStyles<
  Theme,
  {
    size: Props['size'];
    disabled?: boolean;
    outLined?: boolean;
  }
>((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 500,
    background: ({ outLined }) => (outLined ? 'none' : '#008195'),
    border: ({ outLined }) => (outLined ? '1px solid #54e5ff' : 'none'),
    opacity: ({ disabled }) => (disabled ? 0.6 : 1),
    color: ({ outLined }) =>
      outLined ? '#54e5ff!important' : 'white!important',
    borderRadius: 24,
    minWidth: 177,
    boxShadow: '5px 5px 10px 3px rgba(0, 0, 0, 0.2)',
    height: ({ size }) => COMPONENT_SIZES[size ?? 'large'],
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
}));
