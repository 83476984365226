import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import CardGoalDesktop from "./CardGoalDesktop";
import CardGoalMobile from "./CardGoalMobile";
import { useGoals } from "@apollo";

interface propsDataGoal {
  investmentsData: any;
}

const CardGoal = ({ investmentsData }: propsDataGoal) => {
  const isMobile = useMediaQuery("(min-width: 1000px)");
  const [ nameGoal, setNameGoal ] = useState<string>("Objetivo")
  const [ timeLeft, setTimeLeft ] = useState<string>("timeLeft")
  const [ iconGoal, setIconGoal ] = useState<string>("HouseIcon")
  const [ goalId, setGoalId] = useState<number>(0)
  const [ valueGoal, setValueGoal ] = useState<number>(0)
  const {goals} = useGoals();

  const getTimeToGo = (goal) => {

    const goalStartDate = new Date(goal.createdAt)
    const period = goal.info.period
    const goalEndDate = new Date(goalStartDate.getTime());
    goalEndDate.setFullYear(goalEndDate.getFullYear() + period);
    const dateNow = Date.now()

    const timeDifference = goalEndDate - dateNow;
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if(dayDifference < 365){
      if(dayDifference === 1){
        return `${dayDifference} dia`
      }
      else{
        return `${dayDifference} dias`
      }
    }
    else{
      const years = Math.floor(dayDifference/365);
      const days = Math.floor(dayDifference - years*365);

      if(years === 1){
        return `${years} año y ${days} dias`
      }
      else{
        return `${years} años y ${days} dias`
      }
    }
  };

  function setParams() {
    const goalId = (investmentsData[0].goalId)
    const goal = (goals.filter((obj) => obj.id === goalId))[0]
    setNameGoal(goal.name)
    setTimeLeft(getTimeToGo(goal))
    setValueGoal(goal.goal)
    setGoalId(goal.id)
    setIconGoal(goal.type)
  };

  useEffect(() => {
    try {
      setParams()
    } catch (error) {
      // console.log("setting params")
    }
  }, [goals, investmentsData])

  return !isMobile ? (
    <CardGoalMobile
      nameGoal={nameGoal}
      timeLeft={timeLeft}
      iconGoal={iconGoal}
      valueGoal={valueGoal}
      data={investmentsData}
      goalId={goalId}
    />
  ) : (
    <CardGoalDesktop
      nameGoal={nameGoal}
      timeLeft={timeLeft}
      iconGoal={iconGoal}
      valueGoal={valueGoal}
      data={investmentsData}
      goalId={goalId}
    />
  );
};

export default CardGoal;
