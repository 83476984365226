import React, { useState, useEffect, useCallback, useContext } from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import CardGoal from './CardGoals/CardGoal';
import { Route, MovementType, PortfolioLocationCategory } from '@interfaces';
import { navigate } from 'gatsby';
import CardGoalNoInvest from './CardGoals/CardGoalNoInvest';
import { ProfileContext, NewInvestContext, AppContext } from '@context';
import { useGoals, useCurrentUser } from '@apollo';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { ADD_ICON, EXPENSE_ICON } from 'images/AWS/Investments/index';
import { CyanButton } from '@components/Reusables/CyanButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
interface propsGoals {
  data: any;
  sp500: boolean;
  globals: any;
  openMenu: () => void;
}

const Goals = ({ data, sp500, globals, openMenu }: propsGoals) => {
  const [noGoal, setNoGoal] = useState<boolean>(true);
  const [goalCount, setGoalCount] = useState(0);
  const { openNoGoalWarningModal, openNoPersonalDataWarningModal } =
    useContext(AppContext);
  const { profileForm } = useContext(ProfileContext);
  const { openAddFundsModal } = useContext(NewInvestContext);
  const { goals } = useGoals();
  useEffect(() => {
    if (goals.length > 0) {
      setNoGoal(false);
      setGoalCount(goals.length);
    }
  }, [goals]);
  const classes = useStyles({ noGoal });

  const { user: currentUser } = useCurrentUser();

  useEffect(() => {
    if (
      !currentUser?.isIdentityValidated ||
      !currentUser?.isPersonalDataCompleted ||
      !currentUser?.isSpecialDataCompleted
    ) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [currentUser]);

  const goalsNotInvestments = useCallback(() => {
    const idsToDelete: number[] = [];
    data.forEach((element: { goalId: number }[]) => {
      idsToDelete.push(element[0].goalId);
    });

    const goalList = goals.filter((obj) => !idsToDelete.includes(obj.id));
    return goalList;
  }, [data, goals]);

  const [isNoData, setNoData] = useState(false);

  const handleAddFundsClick = () => {
    if (goals.length == 0) {
      openNoGoalWarningModal();
    } else {
      if (
        !currentUser?.isIdentityValidated ||
        !currentUser?.isPersonalDataCompleted ||
        !currentUser?.isSpecialDataCompleted
      ) {
        setNoData(true);
      } else {
        setNoData(false);
        openAddFundsModal(null);
      }
    }
  };

  const isMobile = useMediaQuery('(max-width: 1240px)');
  return (
    <div className={classes.containerAll}>
      <section className={classes.container}>
        <div className={classes.titleCardContainer}>
          {!isMobile && (
            <span className={classes.txtPrincipal}>Mis objetivos</span>
          )}
          <div className={classes.buttonTextContainer}>
            {!isMobile && (
              <span className={classes.txtSecondary}>
                Revisa cuánto has ganado hasta el momento
              </span>
            )}
            {isMobile && (
              <div className={classes.upperInfoContainer}>
                <div className={classes.upperInfoLeft}>
                  <p className={classes.objectivesCount}>{goalCount}</p>
                  <p>Objetivos de ahorro</p>
                </div>
                <div className={classes.upperInfoRight}>
                  {sp500 ? (
                    <CheckRoundedIcon htmlColor="#54e5ff" />
                  ) : (
                    <p className={classes.objectivesCount}>0</p>
                  )}
                  <p>Inversión S&P</p>
                </div>
              </div>
            )}
            <div className={classes.buttonsContainer}>
              <CyanButton
                className={classes.cyanbutton}
                text="Nuevo"
                outLined
                startIcon={
                  <>
                    <img src={ADD_ICON} className={classes.cyanFilter} />
                  </>
                }
                onClick={() => {
                  openMenu();
                }}
              />
              {isNoData && (
                <>
                  <br />
                  <br /> <br />
                  <div className={classes.tooltip}>
                    ¡Completa tu perfil para hacer tu primer aporte!
                  </div>
                  <div className={classes.triangle} />
                </>
              )}
              {!isNoData ? (
                <CyanButton
                  className={classes.cyanbutton}
                  text="Depositar"
                  startIcon={
                    <>
                      <img src={EXPENSE_ICON} className={classes.whiteFilter} />
                    </>
                  }
                  onClick={handleAddFundsClick}
                />
              ) : (
                <CyanButton
                  className={classes.cyanbutton}
                  text="Completar perfil"
                  startIcon={
                    <>
                      <img
                        className={`${classes.filter}`}
                        src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/user02_icon.svg"
                      />
                    </>
                  }
                  onClick={() => navigate(Route.UserInformation)}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <div className={classes.containerCards}>
        {data?.map((dataGrouped, index) => (
          <CardGoal key={index} investmentsData={dataGrouped} />
        ))}
        {goalsNotInvestments()?.map((goal, index) => (
          <CardGoalNoInvest key={index} data={goal} />
        ))}
      </div>
      {/**<div className={classes.newGoal}>
        <img onClick={() => navigate(Route.createGoal)} src={NewGoal} />
        {noGoal && (
          <div className={classes.tooltip}>
            <img src={tooltipDesktop} />
            <span className={classes.textTooltip}>
              <strong>Aun no has creado un objetivo!</strong> Haz click aquí
              para crear uno nuevo y empezar a invertir.
            </span>
            <button
              onClick={() => navigate(Route.createGoal)}
              className={classes.buttonTooltip}
            >
              Ok
            </button>
          </div>
        )}
        </div>*/}
      {/**<section className={classes.container}>
        <div className={classes.blockGrey}></div>
        <div className={classes.cardContainer}>
          <div className={classes.titleCardContainer}>
            <span className={classes.txtPrincipal}>Mis monitoreos</span>
          </div>
        </div>
      </section>
      <div className={classes.containerCards}>
        <CardMonitoreo nameGoal={"gg"} />
      </div>
      <div className={classes.newGoal}>
        <img
          onClick={() => console.log("add new monitor fund")}
          src={AddMonitor}
        />
      </div>**/}
    </div>
  );
};

export default Goals;

const useStyles = makeStyles<Theme, { noGoal: boolean }>((theme) => ({
  containerAll: {},
  cards: {
    display: 'grid',
    gap: '10rem',
  },
  tooltip: {
    background: '#008195',
    padding: '0.2rem 0.8rem',
    borderRadius: 5,
    color: 'white',
    position: 'absolute',
    bottom: '5.5rem',
    fontSize: '0.875rem',
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '10px solid #008195',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
    position: 'absolute',
    bottom: '5rem',
    right: '4rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
    minHeight: 'auto',
    [theme.breakpoints.down(1440)]: {
      minHeight: 'none',
    },
  },
  blockGrey: {
    background: 'rgba(255,255,255,0)',
    width: 1400,
    height: 420,
    borderRadius: 30,
    position: 'absolute',
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0)',
    [theme.breakpoints.down(1440)]: {
      width: 'auto',
      background: 'none',
    },
  },
  buttonTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: '1rem',
    [theme.breakpoints.down(1240)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  depositButton: {
    height: '2.5rem',
    width: '40%',
    borderRadius: '10px',
    background: '#0a5669',
    boxShadow: '1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down(1240)]: {
      width: '100%',
    },
  },
  depositButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  newObjectiveButton: {
    border: '1px solid #084554',
    height: '2.5rem',
    width: '40%',
    borderRadius: '10px',
    boxShadow: '1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down(1240)]: {
      width: '100%',
    },
  },
  newObjectiveText: {
    color: '#fff',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  cardContainer: {
    borderRadius: 30,
  },
  titleCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
  },
  cardGoal: {
    position: 'relative',
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: '#fff',
    [theme.breakpoints.down(1240)]: {
      textAlign: 'left',
    },
  },
  txtSecondary: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 'normal',
    color: '#fff',
    textAlign: 'start',
    width: '100%',
    [theme.breakpoints.down(1240)]: {
      textAlign: 'left',
      fontSize: '0.75rem',
    },
  },
  imageCard: {
    position: 'relative',
    paddingRight: '2rem',
    '& span': {
      color: 'white',
    },
    '& img': {
      width: 460,
    },
  },
  imageCardTitle: {
    position: 'absolute',
    top: 80,
    left: 103,
    fontSize: 40.4,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
  },
  imageCardValue: {
    position: 'absolute',
    top: 130,
    left: 80,
    fontSize: 60,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
    '& span': {
      fontSize: '1rem',
    },
  },
  titleCard: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 200,
  },
  valueCard: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: 600,
    '& span': {
      fontSize: '0.8rem',
      paddingRight: 2,
    },
  },
  button: {
    borderRadius: 24,
    background: 'linear-gradient(161deg, #8250ff -6%, #181331 117%)',
    padding: '0.2rem 0.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 230,
    height: 47,
    color: 'white',
    '& span': {
      display: 'block',
      paddingLeft: 10,
      fontSize: 20,
      fontWeight: 300,
    },
  },
  containerCard: {
    display: 'flex',
    gap: '3rem',
    paddingBottom: '3rem',
  },
  newGoal: {
    width: '90%',
    margin: '0 auto',
    paddingLeft: '6rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(1440)]: {
      paddingTop: '10rem',
      paddingLeft: 0,
    },
    '& >img': {
      cursor: 'pointer',
      zIndex: 4,
      paddingTop: (noGoal) => (!noGoal ? 400 : 'auto'),
    },
  },
  textTooltip: {
    fontSize: 14,
    fontWeight: 300,
    position: 'absolute',
    top: 20,
    width: 300,
    left: 40,
    '& strong': {
      fontWeight: 500,
    },
  },
  buttonTooltip: {
    position: 'absolute',
    fontWeight: 600,
    color: 'white',
    bottom: 0,
    right: 0,
    cursor: 'pointer',
    height: 60,
    width: 122,
  },
  containerCards: {
    marginTop: '3rem',
    margin: '0 auto',
    paddingBottom: '2rem',
    background: 'rgba(0,0,0,0)',
    [theme.breakpoints.down(1240)]: {
      padding: '0 10px',
      //background: 'white',
      borderRadius: '21.8px 21.8px 0 0',
      paddingBottom: '6rem',
    },
  },
  buttonsContainer: {
    marginTop: '1.6rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.56rem',
    position: 'relative',
    '& button': {
      minWidth: '170px',
    },
  },
  cyanFilter: {
    filter:
      'invert(76%) sepia(53%) saturate(1745%) hue-rotate(163deg) brightness(108%) contrast(101%)',
  },
  whiteFilter: {
    height: '1rem',
    filter:
      ' invert(100%) sepia(0%) saturate(7495%) hue-rotate(216deg) brightness(98%) contrast(107%)',
  },
  upperInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem 1.5rem',
  },
  upperInfoLeft: {
    paddingRight: '2.5rem',
    color: '#FFFFFF',
    fontFamily: 'Nunito',
    fontSize: '0.75rem',
    borderRight: '1px solid rgba(255,255,255,0.4)',
    textAlign: 'center',
    gap: '0.5rem',
  },
  upperInfoRight: {
    paddingLeft: '3.5rem',
    color: '#FFFFFF',
    fontFamily: 'Nunito',
    fontSize: '0.75rem',
    textAlign: 'center',
    gap: '0.5rem',
  },
  objectivesCount: {
    color: '#54e5ff',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));
