import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { navigate } from 'gatsby';
import { ButtonPrimary } from '@components/Reusables';
import { Player } from '@lottiefiles/react-lottie-player';
import { ASTRONAUNT_GIF, COINS_3D, ASTRONAUNT } from 'images/AWS/Investments';
import { Route } from '@interfaces';

const NoDataMobile: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.textHolder}>
          <div className={classes.text}>
            <h1>Hmmm aún no hay nada por acá</h1>
          </div>
          <div className={classes.triangle}></div>
        </div>
        <div className={classes.image}>
          {/* <Player
            src={ASTRONAUNT_GIF}
            className='player'
            loop
            autoplay
            speed={1}
          /> */}
          <img src={ASTRONAUNT} alt='Astronaunt' />
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.titleImg}>
            <img
              src={COINS_3D}
              alt='Invertion'
              className={classes.invertionImg}
            />
            <h1 className={classes.title}>
              ¡Comienza tu viaje con $1.000 pesos de regalo!
            </h1>
          </div>
          <p className={classes.textDescription}>
            ¡Crea un objetivo y completa tu perfil de inversión para que te
            abonemos $1.000 CLP y puedas empezar a ahorrar!
          </p>
          <ButtonPrimary
            className={classes.button}
            onClick={() => {
              navigate(Route.createGoal);
            }}
            text='Ir a crear objetivo'
            noDegraded
          />
        </div>
      </div>
    </section>
  );
};

export default NoDataMobile;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#008195',
    height: '46rem',
  },
  cardContainer: {
    width: '100%',
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 30,
    marginTop: '1rem',
  },
  textHolder: {
    display: 'grid',
    justifyItems: 'center',
  },
  text: {
    backgroundColor: '#00323C',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.875rem 2rem 0.875rem 2rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    marginTop: '1rem',
  },
  title: {
    color: '#FFFFFF',
    fontSize: '0.875rem',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'right',
    textTransform: 'uppercase',
    width: '12.35rem',
    // space between lines
    lineHeight: '1.5rem',
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '0.5rem solid transparent',
    borderRight: '0.5rem solid transparent',
    borderTop: '0.5rem solid #00323C',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
  infoContainer: {
    backgroundColor: '#00323C',
    borderRadius: '1.5rem 1.5rem 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    gap: '1.5rem',
  },
  titleImg: {
    display: 'flex',
    gap: '3rem',
  },
  invertionImg: {
    width: '5rem',
    marginTop: '-3rem',
  },
  textDescription: {
    color: '#FFFFFF',
    fontSize: '0.75rem',
    fontFamily: 'Montserrat',
    textAlign: 'left',
    lineHeight: '1.5rem',
  },
  button: {
    width: '100%',
  },
}));
