import React, { useState, useEffect, useContext } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { NewInvestContext } from '@context';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { navigate } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import { otroTarjeta } from '@images';
import {
  AHORRO_AVATAR,
  COMPRAR_CASA_AVATAR,
  DEUDAS_AVATAR,
  FONDO_EMERGENCIA_AVATAR,
  JUBILACION_AVATAR,
  OTRO_AVATAR,
  VIAJAR_AVATAR,
  CLOCK_ICON,
} from 'images/GCP/Investments';
import { Route, MovementType } from '@interfaces';
import { useLocation } from '@reach/router';

interface propsDataGoal {
  nameGoal: string;
  timeLeft: string;
  iconGoal: string;
  className?: string;
  goalId: number;
  data: any;
  valueGoal: number;
  setOpenCard: (value: boolean) => void;
  openCard: boolean;
}

const DataGoal = ({
  nameGoal,
  timeLeft,
  iconGoal,
  className,
  goalId,
  data,
  valueGoal,
  setOpenCard,
  openCard,
}: propsDataGoal) => {
  const [openVertMenu, setOpenVertMenu] = useState(false);
  const classes = useStyles();
  const client = useApolloClient();
  const [icon, setIcon] = useState(otroTarjeta);
  const [value, setValue] = useState<number>(0);
  const [valueRequired, setValueRequired] = useState<number>(0);
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const getTotalInvested = (data) => {
    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.currentValue;
    }, 0);
    return sum;
  };

  useEffect(() => {
    if (data !== undefined) {
      setValueRequired(valueGoal);
      setValue(getTotalInvested(data));
    }
  }, [data, valueGoal]);

  useEffect(() => {
    //TODO: this can be changed to a enum
    if (iconGoal == 'Ahorrar') {
      setIcon(AHORRO_AVATAR);
    }
    if (iconGoal == 'Pagar deudas') {
      setIcon(DEUDAS_AVATAR);
    }
    if (iconGoal == 'Mejorar mi jubilación') {
      setIcon(JUBILACION_AVATAR);
    }
    if (iconGoal == 'Viajar') {
      setIcon(VIAJAR_AVATAR);
    }
    if (iconGoal == 'Comprar una casa') {
      setIcon(COMPRAR_CASA_AVATAR);
    }
    if (iconGoal == 'Fondo de emergencia') {
      setIcon(FONDO_EMERGENCIA_AVATAR);
    }
    if (iconGoal == 'Otro') {
      setIcon(OTRO_AVATAR);
    }
  }, [iconGoal]);
  const { openWithdrawFundsModal, openAddFundsModal, startFlow } =
    useContext(NewInvestContext);
  const { pathname, search: pathSearch } = useLocation();

  const handleAddFundsClick = () => {
    openAddFundsModal(goalId);
  };

  const handleWithdrawFundsClick = () => {
    openWithdrawFundsModal(goalId);
    startFlow({
      movementType: MovementType.WITHDRAWAL,
      comingFrom: pathname + pathSearch,
      isReinvest: false,
    });
  };

  return (
    <section className={`${classes.container} ${className}`}>
      <div className={classes.mainContainer}>
        <div className={classes.cardContainer}>
          <img className={classes.icon} src={icon} />
          <div className={classes.texts}>
            <span className={classes.nameGoal}>{nameGoal}</span>
            <div className={classes.timeGoal}>
              <img src={CLOCK_ICON} className={classes.timerIcon} />
              <div>
                <span className={classes.timeGoalTitle}>Tiempo restante</span>
              </div>
            </div>
            <span className={classes.timeGoalTimeLeft}>{timeLeft}</span>
          </div>
          <div className={classes.moreVert}>
            <button onClick={() => setOpenVertMenu(!openVertMenu)}>
              <MoreVertIcon htmlColor="#00323c" />
            </button>
          </div>
          {isMobile && (
            <div
              className={classes.seeMore}
              onClick={() => setOpenCard(!openCard)}
            >
              {openCard ? <p>Ver menos</p> : <p>Ver mas</p>}
            </div>
          )}
          <div className={openVertMenu ? classes.moreVertMenu : classes.hidden}>
            <button
              className={classes.menuItem}
              onClick={() => navigate(Route.editGoal + `?id=${goalId}`)}
            >
              <p className={classes.menuText}>Editar</p>
            </button>
            <button className={classes.menuItem} onClick={handleAddFundsClick}>
              <p className={classes.menuText}>Agregar fondos</p>
            </button>
            {value > 0 && (
              <button
                className={classes.menuItem}
                onClick={handleWithdrawFundsClick}
              >
                <p className={classes.menuText}>Retirar fondos</p>
              </button>
            )}
          </div>
        </div>
        {
          openCard && isMobile && (
            <div className={classes.saldoContainer}>
              <div className={isMobile ? classes.valueMobile : classes.hidden}>
                <p>Saldo total: ${value.toLocaleString('es')}</p>
              </div>
            </div>
          ) /* ESCONDER CUANDO HACE CLICK*/
        }
      </div>
    </section>
  );
};

export default DataGoal;

export const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  cardContainer: {
    zIndex: 4,
    width: '400px',
    background: '#E6F3F5',
    position: 'relative',
    height: 'auto',
    borderRadius: '16px',
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0.2)',
    padding: '2.0625rem 1.875rem 2.125rem 0.875rem',
    display: 'flex',
    marginLeft: '1.2rem',
    marginTop: '-3rem',
    [theme.breakpoints.down(1440)]: {},
    [theme.breakpoints.down(1280)]: {
      marginLeft: '0rem',
      width: '100%',
      padding: '1.15rem 1.875rem 1.15rem 0.875rem',
      height: '124px',
      background: '#e6f3f5',
      border: 'none',
    },
  },
  icon: {
    marginRight: '1rem',
    width: '5rem',
    [theme.breakpoints.down(1280)]: {
      width: '4.451rem;',
    },
  },
  moreVert: {
    display: 'flex',
    position: 'absolute',
    right: '1rem',
  },
  seeMore: {
    display: 'flex',
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
    '& p': {
      fontFamily: 'Nunito',
      fontSize: ' 0.75rem',
      color: '#7D7C7C',
      textDecoration: 'underline',
      textDecorationColor: '#D3D3D3',
      cursor: 'pointer',
    },
  },
  moreVertMenu: {
    width: '9.6rem',
    height: '7.6rem',
    background: '#fff',
    //margin: '2rem 0 0 0',
    display: 'flex',
    position: 'absolute',
    right: '2.5rem',
    top: '1.2rem',
    flexDirection: 'column',
    borderRadius: '10.4px',
    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
  },
  menuItem: {
    height: '2.6rem',
  },
  menuText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.29,
    letterSpacing: 'normal',
    color: '#7d7c7c',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  menuTextDisabled: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.29,
    letterSpacing: 'normal',
    color: '#D3D3D3',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  nameGoal: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#393e44',
    [theme.breakpoints.down(1279)]: {
      fontSize: '0.908rem',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      color: '#393e44',
    },
  },
  timeGoal: {
    display: 'flex',
    alignItems: 'start',
    top: 85,
    left: 45,
    color: 'black',
    gap: 10,
    [theme.breakpoints.down(1440)]: {
      width: 160,
      top: 75,
      left: 35,
    },
    '& span': {
      display: 'block',
    },
  },
  timeGoalTitle: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1279)]: {
      fontSize: '0.636rem',
      fontWeight: 'normal',
      fontFamily: 'Montserrat',
      color: '#6b6b6b',
      marginTop: 5,
    },
  },
  timeGoalTimeLeft: {
    fontWeight: 'bold',
    color: '#393e44',
    [theme.breakpoints.down(1279)]: {
      fontSize: '0.636rem',
      fontWeight: 500,
      fontFamily: 'Montserrat',
      color: '#393e44',
    },
  },
  iconGoal: {
    position: 'absolute',
    right: -10,
    top: 60,
    zIndex: 4,
    width: 144,
    [theme.breakpoints.down(1440)]: {
      width: 135,
      top: 15,
      left: 270,
    },
    [theme.breakpoints.down(1000)]: {
      width: 112,
      top: 15,
      left: 240,
    },
  },
  texts: {
    display: 'grid',
    gap: 4,
  },
  hidden: {
    display: 'none',
  },
  valueMobile: {
    margin: '1rem 0 0 0',
    borderRadius: '8px',
    border: '1px solid #00a9c7',
    width: '100%',
    height: '3.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 500,
      fontFamily: 'Montserrat',
      color: '#393e44',
    },
  },
  saldoContainer: {
    [theme.breakpoints.down(1240)]: {
      padding: '0 1rem 0 1rem',
    },
  },
  cardIcon: {
    width: '4.451rem',
  },
  timerIcon: {
    width: '1.451rem',
    filter:
      'invert(43%) sepia(0%) saturate(0%) hue-rotate(137deg) brightness(93%) contrast(81%)',
  },
}));
