import { Route } from "@interfaces";
import { navigate } from "gatsby";
import React from "react";
import CardOption from "./CardOptions/CardOptions";
import { useStyles } from "./CreateNew.styles";

interface propsCreate {
  closeMenu: () => void;
}

const CreateNew = ({ closeMenu }: propsCreate) => {
  const classes = useStyles();

  return (
    <section className={classes.containerAll}>
      <section onClick={closeMenu} className={classes.background} />
      <section className={classes.options}>
        <div onClick={closeMenu} className={classes.closeModal} />

        <div className={classes.title}>Crear nuevo...</div>
        <CardOption
          icon="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/safebox_icon%20(1).svg"
          title="Objetivo de Ahorro"
          subtitle="Invierte para lograr una meta"
          onClick={() => {
            navigate(Route.createGoal);
            closeMenu();
          }}
        />
        <CardOption
          icon="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/partners01_icon%20(1).svg"
          title="Inversión S&P"
          subtitle="Invierte en las 500 empresas más grandes de EEUU"
          onClick={() => {
            navigate(Route.investSandP);
          }}
        />
        {/*   <CardOption
          icon="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/partners01_icon%20(1).svg"
          title="Depósito a Plazo"
          subtitle="Invierte un plazo determinado"
          onClick={() => {}}
          disabled
        /> */}
      </section>
    </section>
  );
};
export default CreateNew;
