import React, { useState, useEffect } from 'react';
import { makeStyles, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Ellipse } from 'images/Investments/Goals';
import Progressbar from 'react-js-progressbar';
import { ProgressBarLine } from 'react-progressbar-line';
import { Progress_bar } from '@components/ProgressBar/Progress_Bar';

interface propsDataGoal {
  className?: string;
  data: any;
  valueGoal: number;
}

const TargetGoal = ({ className, data, valueGoal }: propsDataGoal) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1280px)');

  const [value, setValue] = useState<number>(0);
  const [valueRequired, setValueRequired] = useState<number>(0);

  const progress = (value * 100) / valueRequired;

  const getTotalInvested = (data) => {
    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.currentValue;
    }, 0);
    return sum;
  };

  useEffect(() => {
    if (data !== undefined) {
      setValueRequired(valueGoal);
      setValue(getTotalInvested(data));
    }
  }, [data, valueGoal]);

  return (
    <section className={`${classes.container} ${className}`}>
      {/*       <img className={classes.progressBar} src={Ellipse} />
       */}{' '}
      <div className={classes.progressbarContainer} id="progressbarContainer">
        {!isMobile && (
          <Progressbar
            input={progress}
            pathWidth={12}
            pathColor={['#432294', '#8250ff']} // use an array for gradient color.
            trailWidth={20}
            trailColor="rgba(0, 0, 0, 0.05)" // use a string for solid color.
            textStyle={{ fill: '#393e44' }} // middle text style
            shape={isMobile ? 'arc' : 'full circle'}
            clockwise={false}
          >
            <div className={classes.valueProgress}>
              Saldo a <span>${value.toLocaleString('es')}</span>
              <div>de ${valueRequired.toLocaleString('es')}</div>
            </div>
          </Progressbar>
        )}
        {isMobile && (
          <div className={classes.metaContainer}>
            <p className={classes.metaText}>Meta</p>
            <div className={classes.line}>
              {console.log('value:' + value + ' valuereq: ' + valueRequired)}
              <ProgressBarLine
                value={value}
                min={0}
                max={valueRequired + 0.1}
                text=" "
                styles={{
                  path: {
                    stroke: '#4619b6',
                  },
                }}
              />
              <div className={classes.valueProgressMobile}>
                <p>
                  Saldo ${value.toLocaleString('es')} de $
                  {valueRequired.toLocaleString('es')}
                </p>
              </div>
              <div className={classes.centerer}>
                <div className={classes.vector}></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default TargetGoal;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1440)]: {
      position: 'absolute',
    },
    [theme.breakpoints.down(1000)]: {
      display: 'contents',
      left: 5,
      justifyContent: 'end',
      position: 'relative',
    },
  },
  progressbarContainer: {
    width: 280,

    marginTop: '2rem',
    position: 'relative',
    '& text': {
      display: 'none',
    },
    [theme.breakpoints.down(1000)]: {
      marginTop: 20,
      position: 'relative',
      width: '100%',
    },
  },
  valueMobile: {
    borderRadius: '14px',
    border: '1px solid #8352fd',
    width: '100%',
    height: '3.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      textAlign: 'center',
    },
  },
  metaText: {
    width: 'fit-content',
    marginLeft: '1rem',
  },
  valueProgress: {
    position: 'absolute',
    top: 62,
    display: 'grid',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    '& span': {
      fontWeight: 'bold',
      display: 'block',
      fontSize: 26,
    },
    '& >div': {},
  },
  valueProgressMobile: {
    margin: '1rem 0 1rem 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  metalIcon: {
    position: 'absolute',
    top: -5,
    left: 110,
    cursor: 'pointer',
  },
  progressBar: {
    position: 'absolute',
    [theme.breakpoints.down(1000)]: {
      width: 160,
    },
  },
  cardContainer: {
    padding: '1.25rem 2.8125rem 1.25rem 6.25rem',
    background: 'white',
    borderRadius: 30,
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0,0,0,0.2)',
  },
  valuesEllipse: {
    position: 'absolute',
    top: 80,
    left: 70,
    textAlign: 'center',
    [theme.breakpoints.down(1000)]: {
      top: 40,
      right: 5,
      paddingRight: '2rem',
      left: 0,
      width: '100%',
      justifyContent: 'end',
      display: 'grid',
    },
    '& span': {
      display: 'block',
    },
    '& >span': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1000)]: {
      fontSize: 14,
    },
  },
  value: {
    fontWeight: 'bold',
    fontSize: 38,
    [theme.breakpoints.down(1000)]: {
      fontSize: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    '& >span': {
      fontSize: 12,
      [theme.breakpoints.down(1000)]: {
        fontSize: 10,
      },
    },
  },
  lastValue: {
    justifyContent: 'center',
    gap: 2,
    value: {
      '& >span': {
        fontSize: 12,
        [theme.breakpoints.down(1000)]: {
          fontSize: 10,
        },
      },
    },
  },
  tooltip: {},
  line: {
    width: '100%',
    height: 12,
    margin: '1rem 0 0 0',
    [theme.breakpoints.down(1280)]: {
      padding: ' 0 1rem 0 1rem',
    },
    '& svg': {
      borderRadius: '15px',
      boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  centerer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  vector: {
    width: '90%',
    height: '0.063rem',
    backgroundColor: '#d6d6d6',
  },
}));
