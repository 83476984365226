import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { TimerIcon } from 'images/Investments/Goals';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useMediaQuery } from '@material-ui/core';
import {
  ahorroTarjeta,
  casaTarjeta,
  deudasTarjeta,
  jubilacionTarjeta,
  viajeTarjeta,
  emergenciaTarjeta,
  otroTarjeta,
  AlertIcon,
  TrashCan,
} from '@images';
import { EditIcon } from 'images/Investments/Goals';
import AddIcon from '@material-ui/icons/Add';
import {
  Goal,
  MovementType,
  PortfolioLocationCategory,
  Route,
} from '@interfaces';
import { NewInvestContext, AppContext } from '@context';
import { navigate } from 'gatsby';
import { useApolloClient } from '@apollo/client';
import { deleteGoal, useCurrentUser } from '@apollo';
import {
  AHORRO_AVATAR,
  COMPRAR_CASA_AVATAR,
  DEPOSITO_A_PLAZO_AVATAR,
  DEUDAS_AVATAR,
  FONDO_EMERGENCIA_AVATAR,
  INVERSION_SP_AVATAR,
  JUBILACION_AVATAR,
  OTRO_AVATAR,
  VIAJAR_AVATAR,
  CLOCK_ICON,
} from 'images/GCP/Investments';

interface propsDataGoal {
  data: Goal;
}

const CardGoalNoInvest = ({ data }: propsDataGoal) => {
  const classes = useStyles();
  const [icon, setIcon] = useState(OTRO_AVATAR);
  const client = useApolloClient();
  const { openNoPersonalDataWarningModal } = useContext(AppContext);
  const { openAddFundsModal } = useContext(NewInvestContext);
  const { user: currentUser } = useCurrentUser();
  const [openVertMenu, setOpenVertMenu] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1279px)');

  const iconGoal = data.type;
  useEffect(() => {
    if (iconGoal == 'Ahorrar') {
      setIcon(AHORRO_AVATAR);
    }
    if (iconGoal == 'Pagar deudas') {
      setIcon(DEUDAS_AVATAR);
    }
    if (iconGoal == 'Mejorar mi jubilación') {
      setIcon(JUBILACION_AVATAR);
    }
    if (iconGoal == 'Viajar') {
      setIcon(VIAJAR_AVATAR);
    }
    if (iconGoal == 'Comprar una casa') {
      setIcon(COMPRAR_CASA_AVATAR);
    }
    if (iconGoal == 'Fondo de emergencia') {
      setIcon(FONDO_EMERGENCIA_AVATAR);
    }
    if (iconGoal == 'Otro') {
      setIcon(OTRO_AVATAR);
    }
  }, [iconGoal]);

  const handleAddFundsClick = () => {
    if (
      !currentUser?.isIdentityValidated ||
      !currentUser?.isPersonalDataCompleted ||
      !currentUser?.isSpecialDataCompleted
    ) {
      openNoPersonalDataWarningModal();
    } else {
      openAddFundsModal(data.id);
    }
  };

  const handleDelete = () => {
    try {
      deleteGoal(data.id, client);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <section className={`${classes.container}`}>
      <div className={classes.cardContainerGoal}>
        <img src={icon} className={classes.cardIcon} />
        <div className={classes.texts}>
          <span className={classes.nameGoal}>{data.name}</span>
          <div className={classes.timeGoal}>
            <img src={CLOCK_ICON} className={classes.timerIcon} />
            <div>
              <span className={classes.timeGoalTitle}>Tiempo restante</span>
            </div>
          </div>
          <span className={classes.timeGoalTimeLeft}>
            {data.info.period} años
          </span>
        </div>
        <div className={classes.moreVert}>
          <button onClick={() => setOpenVertMenu(!openVertMenu)}>
            <MoreVertIcon htmlColor="#00323c" />
          </button>
        </div>
        <div className={openVertMenu ? classes.moreVertMenu : classes.hidden}>
          <button
            className={classes.menuItem}
            onClick={() => navigate(Route.editGoal + `?id=${data.id}`)}
          >
            <p className={classes.menuText}>Editar</p>
          </button>
          <button className={classes.menuItem} onClick={handleAddFundsClick}>
            <p className={classes.menuText}>Agregar fondos</p>
          </button>
          <button className={classes.menuItem} onClick={handleDelete}>
            <p className={classes.menuText}>Eliminar</p>
          </button>
        </div>
      </div>
      <div className={classes.cardContainerAlert}>
        <img src={AlertIcon} />
        <div className={classes.noInvest}>
          <span>Aún no has invertido en este objetivo</span>
        </div>
      </div>
      <div className={classes.second}>
        {/*<div className={classes.buttonAdd} onClick={handleAddFundsClick}>
          <AddIcon className={classes.addIcon} />
          <span>Depositar</span>
        </div>
        <div
          onClick={() => navigate(Route.editGoal+`?id=${data.id}`)}
          className={`${classes.buttonEdit}`}
        >
          <img src={EditIcon} />
        </div>
        <div
          onClick={handleDelete}
          className={`${classes.buttonTrash}`}
        >
          <img src={TrashCan} />
          <span>Eliminar objetivo</span>
  </div>*/}
      </div>
    </section>
  );
};

export default CardGoalNoInvest;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '6.25rem 26.25rem 5rem 4rem',
    gap: 25,
    height: 240,
    borderRadius: 30,
    background: 'white',
    display: 'flex',
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(1279)]: {
      margin: '1.125rem 0 0 0',
      height: 350,
      //padding: "0 1.875rem 0 1.875rem",
      boxShadow: 'none',
      flexDirection: 'column',
      background: 'none',
    },
  },
  cardContainerGoal: {
    zIndex: 4,
    position: 'relative',
    width: 400,
    height: 171,
    margin: '-2.0625rem 0 0 2.5rem',
    background: '#E6F3F5',
    borderRadius: 16,
    boxShadow: '0.3125rem 0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.2)',
    padding: '2.0625rem 1.875rem 2.125rem 0.875rem',
    display: 'flex',
    gap: 29,
    alignItems: 'center',
    [theme.breakpoints.down(1279)]: {
      width: '100%',
      height: 124,
      margin: '-3.125rem 0 0 0',
      padding: '1.15rem 1.875rem 1.15rem 0.875rem',
      border: 'none',
      background: '#e6f3f5',
    },
    '& img': {},
  },
  cardContainerAlert: {
    width: 171,
    height: 171,
    margin: '-2.0625rem 0 0 0',
    background: '#E6F3F5',
    borderRadius: 14,
    boxShadow: '0.3125rem 0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.2)',
    padding: '1.125rem 0.9375rem 1.125rem 0.9375rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(1279)]: {
      width: '100%',
      height: '4.125rem',
      margin: '-1rem auto',
      flexDirection: 'initial',
      background: '#e6f3f5',
      border: 'none',
    },
  },
  nameGoal: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#393e44',
    [theme.breakpoints.down(1279)]: {
      fontSize: '0.908rem',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      color: '#393e44',
    },
  },
  timeGoal: {
    display: 'flex',
    alignItems: 'start',
    top: 85,
    left: 45,
    color: 'black',
    gap: 10,
    [theme.breakpoints.down(1440)]: {
      top: 75,
      left: 35,
    },
    '& span': {
      display: 'block',
    },
  },
  timeGoalTitle: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.text.secondary,

    [theme.breakpoints.down(1279)]: {
      fontSize: '0.636rem',
      fontWeight: 'normal',
      fontFamily: 'Montserrat',
      color: '#6b6b6b',
      marginTop: 5,
    },
  },
  timeGoalTimeLeft: {
    fontWeight: 'bold',
    color: '#393e44',
    width: 144,
    [theme.breakpoints.down(1440)]: {
      width: 'auto',
    },
    [theme.breakpoints.down(1279)]: {
      fontSize: '0.636rem',
      fontWeight: 500,
      fontFamily: 'Montserrat',
      color: '#393e44',
    },
  },
  iconGoal: {
    position: 'absolute',
    right: -10,
    top: 60,
    zIndex: 4,
    width: 144,
    [theme.breakpoints.down(1440)]: {
      width: 135,
      top: 15,
      left: 270,
    },
    [theme.breakpoints.down(1000)]: {
      width: 112,
      top: 15,
      left: 240,
    },
  },
  texts: {
    display: 'grid',
    gap: 4,
  },
  addIcon: {
    width: 35,
    height: 35,
    [theme.breakpoints.down(800)]: {
      width: 28,
      height: 28,
    },
  },
  buttonAdd: {
    cursor: 'pointer',
    borderRadius: 24,
    background: 'linear-gradient(161deg, #8250ff -6%, #181331 117%)',
    padding: '0.2rem 0.7rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
    color: 'white',
    gap: 10,
    width: 290,
    '& span': {
      display: 'block',
      fontSize: 25,
      fontWeight: 'normal',
      paddingLeft: 5,
    },
    [theme.breakpoints.down(800)]: {
      width: 200,
      '& span': {
        fontSize: 16,
        fontWeight: 500,
      },
    },
  },
  buttonEdit: {
    cursor: 'pointer',
    '& img': {
      height: '100%',
      width: 'auto',
    },
  },
  buttonTrash: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      height: '50%',
      width: 'auto',
    },
    '& span': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down(800)]: {
      position: 'absolute',
      marginTop: 64,
    },
  },
  second: {
    display: 'flex',
    gap: 28,
    position: 'absolute',
    height: 66,
    zIndex: 4,
    justifyItems: 'center',
    margin: '8.75rem 0 0 -5rem',
    [theme.breakpoints.down(800)]: {
      width: '100%',
      //height: 41,
      height: 0,
      margin: '0 auto',
      position: 'initial',
    },
  },
  noInvest: {
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    color: '#7d7c7c',
    textAlign: 'center',
    [theme.breakpoints.down(1279)]: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.75rem',
      lineHeight: 2,
      letterSpacing: 'normal',
      color: '#7d7c7c',
    },
  },
  vector: {
    width: '90%',
    height: '0.063rem',
    backgroundColor: '#d6d6d6',
  },
  centerer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  moreVert: {
    display: 'flex',
    position: 'absolute',
    right: '1rem',
    top: '1.2rem',
  },
  hidden: {
    display: 'none',
  },
  moreVertMenu: {
    width: '9.6rem',
    height: '7.6rem',
    background: '#fff',
    //margin: '2rem 0 0 0',
    display: 'flex',
    position: 'absolute',
    right: '2.5rem',
    top: '1.2rem',
    flexDirection: 'column',
    borderRadius: '10.4px',
    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
  },
  menuItem: {
    height: '2.6rem',
  },
  menuText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.29,
    letterSpacing: 'normal',
    color: '#7d7c7c',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  menuTextDisabled: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.29,
    letterSpacing: 'normal',
    color: 'lightGray',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  cardIcon: {
    width: '4.451rem',
  },
  timerIcon: {
    width: '1.451rem',
    filter:
      'invert(43%) sepia(0%) saturate(0%) hue-rotate(137deg) brightness(93%) contrast(81%)',
  },
}));
