import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { LayoutOnBoarding, SEO } from '@components';
import {
  useCurrentCashBalance,
  usePortfolios,
  useUserInvestments,
  useHistoricalProfit,
  useCurrentUser,
} from '@apollo';
import { useAuth, useInvestmentsTotals } from '@hooks';
import { LayoutMobileInvestments } from '@components/Investments/Layout';
import { TUserInfo } from 'react-use-hotjar/dist/types';
import useHotjar from 'react-use-hotjar';
import { Route } from '@interfaces';
import InvestmentsLayout from '@components/Investments/InvestmentsLayout/InvestmentsLayout';
import CreateNew from '@components/Investments/CreateNew/CreateNew';

const Investments: React.FC<PageProps> = () => {
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const [isOpenMenu, setOpenMenu] = useState(false);

  useAuth({ redirectIfNotLogged: true });

  const { identifyHotjar } = useHotjar();
  const myCustomLogger = console.log;
  const { user: currentUser } = useCurrentUser();
  const logininfo = {
    name: currentUser?.name,
    identification: process.env.HOTJAR_ID || '0',
  };
  useEffect(() => {
    if (logininfo) {
      const { identification, ...restOfInformation } = {
        name: currentUser?.name,
        identification: process.env.HOTJAR_ID || '0',
      };
      identifyHotjar(
        identification,
        restOfInformation.name as unknown as TUserInfo,
        myCustomLogger
      );
    }

    if (currentUser?.company && !currentUser.company.canInvest) {
      navigate(Route.finance);
    }
  }, [currentUser]);

  return (
    <>
      <SEO
        title="Invierte y Ahorra - Rokin"
        description="Invierte en tus objetivos, simplifica tu ahorro y alcanza tus metas"
      />
      {!isMobile && (
        <LayoutMobileInvestments activeInvest>
          <InvestmentsLayout openMenu={() => setOpenMenu(true)} />
        </LayoutMobileInvestments>
      )}
      {isMobile && (
        <LayoutOnBoarding activeInvest activeRokipedia={false}>
          <InvestmentsLayout openMenu={() => setOpenMenu(true)} />
        </LayoutOnBoarding>
      )}
      {isOpenMenu && <CreateNew closeMenu={() => setOpenMenu(false)} />}
    </>
  );
};

export default Investments;
