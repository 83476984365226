import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Addicon from '@material-ui/icons/Add';
import { EditIcon, WithDrawIcon } from 'images/Investments/Goals';
import DataGoal from './DataGoal';
import GraphicGoal from './GraphicGoal';
import TargetGoal from './TargetGoal';
import { NewInvestContext } from '@context';
import { navigate } from 'gatsby';
import { Route, MovementType } from '@interfaces';
import { useLocation } from '@reach/router';

interface propsDataGoal {
  nameGoal: string;
  timeLeft: string;
  iconGoal: string;
  data: any;
  valueGoal: number;
  goalId: number;
}

const CardGoalDesktop = ({
  nameGoal,
  timeLeft,
  iconGoal,
  data,
  valueGoal,
  goalId,
}: propsDataGoal) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const [valueRequired, setValueRequired] = useState<number>(0);

  const getTotalInvested = (data) => {
    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.currentValue;
    }, 0);
    return sum;
  };

  useEffect(() => {
    if (data !== undefined) {
      setValueRequired(valueGoal);
      setValue(getTotalInvested(data));
    }
  }, [data, valueGoal]);

  return (
    <section className={classes.container}>
      <div className={classes.dataGoal}>
        <DataGoal
          value2={value}
          nameGoal={nameGoal}
          timeLeft={timeLeft}
          iconGoal={iconGoal}
          goalId={goalId}
          data={data}
        />
      </div>
      <div className={classes.graphic}>
        <GraphicGoal data={data} />
      </div>
      <div className={classes.targetGoal}>
        <TargetGoal data={data} valueGoal={valueGoal} />
      </div>
    </section>
  );
};

export default CardGoalDesktop;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '90%',
    margin: '0 auto',
    marginBottom: '10rem',

    height: 560,
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '30px',
    boxShadow: '0.25rem 0.25rem 0.75rem 0 rgb(0 0 0 / 30%)',
  },
  cardContainer: {
    position: 'relative',
  },
  nameGoal: {
    position: 'absolute',
    top: 0,
  },
  dataGoal: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    position: 'relative',
    gap: 0,
  },
  buttonAdd: {
    borderRadius: 24,
    background: 'linear-gradient(161deg, #8250ff -6%, #181331 117%)',
    padding: '0.2rem 0.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 41,
    color: 'white',
    fontSize: '1rem',
    gap: 10,
    width: 180,
    '& span': {
      display: 'block',
      fontSize: '1rem',
      fontWeight: 500,
      paddingLeft: 5,
    },
  },
  buttonWithdraw: {
    border: '0.1875rem solid #8250ff',
    background: 'none',
    color: theme.palette.text.primary,
    '& span': {
      fontWeight: 400,
    },
  },
  buttons: {
    display: 'flex',
    height: 41,
    alignItems: 'center',
    gap: 15,
    [theme.breakpoints.down(1440)]: {
      position: 'absolute',
      top: 200,
    },
    '& div:hover': {
      cursor: 'pointer',
    },
  },
  graphic: {
    position: 'absolute',
    top: 65,
    left: 390,
    zIndex: 2,
    [theme.breakpoints.down(1440)]: {
      display: 'flex',
      justifyContent: 'center',
      width: 800,
    },
    [theme.breakpoints.up(1280)]: {
      marginLeft: '-2rem',
    },
  },
  buttonEdit: {
    cursor: 'pointer',
  },
  addIcon: {
    width: 28,
    height: 28,
  },
}));
