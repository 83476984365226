import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { ButtonText } from '..';
import { Line } from 'react-chartjs-2';
import {
  DEPOSIT_ICON_WHITE,
  ADD_GOAL_WHITE,
  MONITOREAR_ICON_GREEN,
  CALENDAR_ICON_GREEN,
  TREND_ICON_GREEN,
  GRAPH_ICON,
  CALENDAR_ICON_01,
} from 'images/AWS/Investments/index';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import {
  Calendar,
  EditIcon,
  Profile,
  ProfitabilityIcon,
} from 'images/Investments';
import { getUserTransactionsSummary } from '@apollo';
import {
  defaultDataPrimary,
  chartOptions,
  chartOptionsMobile,
} from '@components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface propsProfitability {
  globals: any;
  profileForm: any;
  historicalGraphData: any;
}

const ProfitabilityMobile: React.FC = ({
  globals,
  profileForm,
  historicalGraphData,
}: propsProfitability) => {
  const classes = useStyles();
  const [firstDeposit, setFirstDeposit] = useState<number>(0);
  const [investorProfile, setInvestorProfile] = useState<string>('Moderado');
  const { userTransactionsSummary, loading: loadingTransactionsSummary } =
    getUserTransactionsSummary();
  const [graphData, setGraphData] = useState(defaultDataPrimary);
  const isMobile = useMediaQuery('(min-width: 1280px)');

  function getFirstDeposit() {
    const firstMovement = userTransactionsSummary.filter((obj) => {
      return obj.type === 'DEPOSIT';
    })[0];

    const now = new Date();

    if (firstMovement !== undefined) {
      const date1 = new Date(firstMovement.createdAt);
      const diff = Math.abs(date1.getTime() - now.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      return diffDays;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    setInvestorProfile(profileForm.investorProfile);
    setFirstDeposit(getFirstDeposit());
    if (historicalGraphData) {
      const values = historicalGraphData.map((e) => e.value);
      const labels = historicalGraphData.map(
        (e) => e.date.split('-')[0] + '-' + e.date.split('-')[1]
      );

      setGraphData({
        labels: labels,
        datasets: [
          {
            fill: true,
            label: 'Saldo',
            data: values,
            borderColor: '#4619b6',
            backgroundColor: 'rgba(131, 82, 253, 0.3)',
            pointStyle: 'circle',
            radius: 6,
            pointRadius: 1,
            lineTension: 0.3,
          },
        ],
      });
    }
  }, [globals, profileForm, historicalGraphData]);

  return (
    <section className={classes.container}>
      <div className={classes.graphic}>
        <div className={classes.saldo}>
          <div></div>Saldo
        </div>
        <Line options={chartOptionsMobile} data={graphData} />
      </div>
      <div className={classes.rightData}>
        <div className={classes.blockBase}>
          <img src={CALENDAR_ICON_01} className={classes.cyanFilter} />
          <div>
            <p className={classes.cardText}>
              Llevo<strong> {firstDeposit} </strong> dias ahorrando
            </p>
          </div>
        </div>
        <div className={classes.blockBase}>
          <img src={GRAPH_ICON} className={classes.cyanFilter} />
          <div>
            <p className={classes.cardText}>
              Mi rentabilidad es{' '}
              <strong> {globals.profitability.toFixed(1)}%</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfitabilityMobile;

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    fontFamily: 'Montserrat',
    display: 'flex',
    gap: '8rem',
    margin: '7rem 0rem 8rem 0rem',
    [theme.breakpoints.down(1240)]: {
      gap: '2rem',
      flexDirection: 'column',
      margin: '2rem 0rem 7rem 0rem',
    },
  },
  graphic: {
    borderRadius: '10px',
    boxShadow: '0.25rem 0.25rem 0.75rem 0 rgb(0 0 0 / 30%)',
    padding: '1.5rem',
    //width: 365,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: 'white',
    [theme.breakpoints.up(1280)]: {
      height: 350,
      width: 550,
      position: 'relative',
      marginRight: '0%',
    },
  },
  daysSaving: {
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    lineHeight: 1.2,
    fontSize: 20,
    '& strong': {
      fontWeight: 'bold',
    },
  },
  cardText: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '& strong': {},
  },
  value: {
    fontSize: 36,
    fontWeight: 600,
    fontFamily: 'Nunito!important',
  },
  valuePorcetage: {
    marginTop: 12,
  },
  ttletxt: {},
  blockBase: {
    display: 'flex',
    background: 'rgba(240, 240, 240)',
    boxShadow: '0.25rem 0.25rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.25)',
    height: '2.8rem',
    width: '100%',
    padding: 19,
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1.5,
    fontSize: 18,
    borderRadius: '8px',
    gap: '1rem',
    '& div': {
      width: '100%',
      textAlign: 'start',
    },
  },
  profitability: {
    fontFamily: 'Nunito!important',
    fontSize: 20,
  },
  blockThree: {
    gridArea: 'three',
    background: 'rgba(240, 240, 240, 0.9)',
    boxShadow: '0.25rem 0.25rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.25)',
    width: 360,
    height: 110,
    padding: 19,
    display: 'flex',
    borderRadius: 20,
    justifyContent: 'space-between',
    [theme.breakpoints.up(1000)]: {
      width: 290,
      gap: '1rem',
    },
  },
  people: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: 64,
      marginRight: 20,
    },
    '& div': {
      display: 'grid',
      '& span': {
        display: 'block',
      },
    },
  },
  profileInvest: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 19,
  },
  profile: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    fontWeight: 600,
  },
  button: {
    display: 'flex',
    width: 110,
    height: 24,
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up(1000)]: {
      width: 55,
    },
    '& img': {
      width: 24,
    },
    '& button': {
      fontSize: '1rem',
    },
  },
  rightData: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  saldo: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 7,
    left: 42,
    fontSize: 14,
    fontWeight: 'bold',
    '& >div': {
      background: '#0a5669',
      width: 10,
      height: 10,
      borderRadius: '100%',
      marginRight: 5,
    },
  },
  cyanFilter: {
    filter:
      'invert(43%) sepia(97%) saturate(691%) hue-rotate(148deg) brightness(95%) contrast(101%)',
  },
}));
