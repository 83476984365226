import React, { useContext, useState } from 'react';
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import Addicon from '@material-ui/icons/Add';
import { EditIcon, WithDrawIcon } from 'images/Investments/Goals';
import DataGoal from './DataGoal';
import GraphicGoal from './GraphicGoal';
import TargetGoal from './TargetGoal';
import { NewInvestContext } from '@context';
import { navigate } from 'gatsby';
import { Route, MovementType } from '@interfaces';
import { useLocation } from '@reach/router';

interface propsDataGoal {
  nameGoal: string;
  timeLeft: string;
  iconGoal: string;
  data: any;
  valueGoal: number;
  goalId: number;
}

const CardGoalMobile = ({
  nameGoal,
  timeLeft,
  iconGoal,
  data,
  valueGoal,
  goalId,
}: propsDataGoal) => {
  const [openCard, setOpenCard] = useState(false);
  const classes = useStyles({ openCard });
  const isMobile = useMediaQuery('(min-width: 1440px)');

  return (
    <section className={classes.container}>
      <div className={classes.dataGoal}>
        <DataGoal
          nameGoal={nameGoal}
          timeLeft={timeLeft}
          iconGoal={iconGoal}
          data={data}
          goalId={goalId}
          valueGoal={valueGoal}
          setOpenCard={setOpenCard}
          openCard={openCard}
        />

        {
          openCard && (
            <>
              <div className={classes.graphic}>
                <GraphicGoal data={data} valueGoal={valueGoal} />
              </div>
              <div className={classes.targetGoal}>
                <TargetGoal data={data} valueGoal={valueGoal} />
              </div>
            </>
          ) /* ESCONDER CUANDO HACE CLICK*/
        }
      </div>
    </section>
  );
};

export default CardGoalMobile;

export const useStyles = makeStyles<Theme, { openCard: boolean }>((theme) => ({
  container: {
    margin: '4rem 0 5rem 0',
    position: 'relative',
    width: '100%',
  },
  cardContainer: {
    position: 'relative',
  },
  nameGoal: {
    position: 'absolute',
    top: 0,
  },
  dataGoal: {
    justifyContent: 'space-between',
    margin: '0 auto',
    position: 'relative',
    gap: 0,
    [theme.breakpoints.down(1440)]: {
      display: 'block',
    },
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      height: ({ openCard }) => (openCard ? '720px' : 'auto'),
      backgroundColor: '#FFFFFF',
      borderRadius: '0 0 16px 16px',
    },
  },
  buttonAdd: {
    borderRadius: 24,
    background: 'linear-gradient(161deg, #8250ff -6%, #181331 117%)',
    padding: '0.2rem 0.4rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 41,
    color: 'white',
    fontSize: '1rem',
    width: 180,
    '& span': {
      display: 'block',
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  buttonWithdraw: {
    border: '0.1875rem solid #8250ff',
    background: 'none',
    color: theme.palette.text.primary,
    '& span': {
      fontWeight: 400,
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateAreas: "'Add Add' 'Retirar Edit'",
    height: 41,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
    width: 300,
    margin: '1.25rem auto -1.125rem auto',
    [theme.breakpoints.down(1440)]: {
      height: 'auto',
    },
    '& div:hover': {
      cursor: 'pointer',
    },
    '& .add': {
      gridArea: 'Add',
    },
    '& .retirar': {
      gridArea: 'Retirar',
    },
    '& .edit': {
      gridArea: 'Edit',
    },
  },
  graphic: {
    position: 'absolute',
    top: 110,
    left: 350,
    zIndex: 2,
    [theme.breakpoints.down(1440)]: {
      position: 'relative',
      left: 0,
      top: 0,
    },
  },
  buttonEdit: {
    cursor: 'pointer',
  },
  saldo: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 20,
    left: 130,
    fontSize: 14,
    fontWeight: 'bold',
    [theme.breakpoints.down(1440)]: {
      position: 'relative',
      fontSize: 12,
      top: 105,
      left: 10,
      display: 'none',
    },
    '& >div': {
      background: theme.palette.primary.main,
      width: 20,
      height: 20,
      borderRadius: '100%',
      marginRight: 5,
      [theme.breakpoints.down(1440)]: {
        width: 10,
        height: 10,
        borderRadius: '100%',
        marginRight: 15,
      },
    },
  },
  addIcon: {
    width: '2.1rem',
    height: '2.1rem',
  },
  targetGoal: {
    [theme.breakpoints.down(1440)]: {
      position: 'relative',
      left: 0,
      top: 0,
    },
  },
}));
