import React, { useState, useEffect } from "react";
import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { parseNumber } from "@utils";
import { StatusMovements } from "@data";
import { I } from "@images";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const Table = ({ data = null }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600));
  const [name, setName] = useState<string>(undefined);
  const [description, setDescription] = useState<string>(undefined);
  const isMobileTest = useMediaQuery("(max-width: 1000px)");

  const earnings = data.currentValue - data.totalInvested + data.totalWithdrawn;

  useEffect(() => {
    if (!data.name) {
      setName(data.fund.name);
    } else {
      setName(data.name);
    }

    if (!data.serie) {
      setDescription("Múltiples fondos");
    } else {
      setDescription(data.serie.name);
    }
  });

  return (
    <div
      className={classes.container}
      style={{ padding: "0.625rem 0.625rem 0.625rem 0.3125rem" }}
    >
      <div className={classes.tabsDiv}>
        <div className={classes.card}>
          <div className={classes.cardName}>
            {name}
            <span>{description}</span>
          </div>
        </div>
        {!isMobileTest && (
          <div className={classes.saldo}>
            Saldo<span>${parseNumber(data.currentValue)}</span>
          </div>
        )}
        {!isMobileTest && (
          <>
            <div className={classes.tabs}>
              <div>
                Aporte
                <img src={I} />
              </div>
              <span>${parseNumber(data.totalInvested)}</span>
            </div>
            <div className={classes.tabs}>
              Ganancia<span>${parseNumber(earnings)}</span>
            </div>
            <div className={classes.tabs}>
              Retirado<span>${parseNumber(data.totalWithdrawn)}</span>
            </div>
          </>
        )}
      </div>
      {isMobileTest && (
        <div className={classes.messageMobile}>
          Saldo:<span> ${parseNumber(data.currentValue)}</span>
        </div>
      )}
      {data.status && data.pendingInvestments !== 0 && (
        <div className={classes.message}>
          {StatusMovements.find((mov) => data.status == mov.status)?.text} -{" "}
          {!isMobile ? "En tránsito" : ""} $
          {parseNumber(data.pendingInvestments)}
        </div>
      )}
      {isMobileTest && (
        <section className={classes.values}>
          <div className={classes.tabs}>
            <div>
              Aporte
              <img src={I} />
            </div>
            <span>${parseNumber(data.totalInvested)}</span>
          </div>
          <div className={classes.tabs}>
            Ganancia<span>${parseNumber(earnings)}</span>
          </div>
          <div className={classes.tabs}>
            Retirado<span>${parseNumber(data.totalWithdrawn)}</span>
          </div>
        </section>
      )}
    </div>
  );
};

export default Table;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    justifyContent: "center",
    width: "auto",
  },
  card: {
    height: 66,
    width: 240,
    border: "0.0625rem solid #008296",
    borderRadius: 15,
    boxShadow: "0.25rem 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2)",
    padding: "0.625rem 1.125rem 0.625rem 1.375rem",
    position: "relative",
    background: "#fff",
    zIndex: 10,
    [theme.breakpoints.down(1000)]: {
      width: "100%",
      boxShadow: "0 0 0 0",
    },
  },
  values: {
    display: "flex",
    gap: "0.5rem",
    maxWidth: 265,
    justifyContent: "space-between",
    [theme.breakpoints.down(1000)]: {
      gap: 0,
      maxWidth: "100%",
    },
  },
  cardName: {
    fontSize: 16,
    color: "#393e44",
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "& >span": {
      display: "block",
      fontSize: 14,
      fontWeight: "normal",
    },
  },
  saldo: {
    width: "8.4375rem",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "0.5rem 0.9375rem 0 1.875rem",
    borderRadius: "0 0.6875rem 0.6875rem 0",
    boxShadow: "0.25rem 0.25rem 0.625rem 0.125rem rgba(0, 0, 0, 0.15)",
    border: "solid 0.1875rem #fff",
    fontSize: 16,
    color: "#008296",
    fontFamily: "Montserrat",
    fontWeight: 600,
    display: "grid",
    textAlign: "center",
    margin: "0 0 0 -3.3125rem",
    "& >span": {
      color: "#393e44",
      fontFamily: theme.typography.fontFamily,
    },
  },
  tabsDiv: {
    display: "flex",
    gap: 30,
  },
  tabs: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "normal",
    color: "#008296",
    textAlign: "center",
    display: "grid",
    paddingTop: 10,
    [theme.breakpoints.down(1000)]: {
      fontSize: 14,
    },
    "& >div": {
      display: "flex",
      gap: 3,
      "& >img": {
        height: 22,
        width: "auto",
      },
    },
    "& >span": {
      color: "#393e44",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
    },
  },
  message: {
    background: "#008296",
    borderRadius: "0 0.6875rem 0.6875rem 0.6875rem",
    boxShadow: "0.25rem 0.25rem 0.625rem 0.125rem rgba(0, 0, 0, 0.15)",
    fontSize: 14,
    color: "#efefef",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    textAlign: "left",
    height: 37,
    width: "48%",
    marginTop: "-0.75rem",
    padding: "0.75rem 0 0 1.5rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down(1000)]: {
      padding: "0.9375rem 0 0.3125rem 1.25rem",
      width: "100%",
    },
  },
  messageMobile: {
    background: "white",
    borderRadius: "0 0 0.6875rem 0.6875rem",
    boxShadow: "0.25rem 0.25rem 0.625rem 0.125rem rgba(0, 0, 0, 0.15)",
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    height: 40,
    width: "100%",
    marginTop: "-0.75rem",
    padding: "0.75rem 0 0 1.5rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
    position: "relative",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(1000)]: {
      padding: "0.875rem 0 0 0",
    },
    "& span": {
      color: theme.palette.text.primary,
    },
  },
  arrow: {
    position: "absolute",
  },
}));
