import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { NewInvestContext, ProfileContext, AppContext } from '@context';
import { parseNumber } from '@utils';
import { Route } from '@interfaces';
import { useGoals, useCurrentUser } from '@apollo';
import { Player } from '@lottiefiles/react-lottie-player';
import piggy from 'images/Animations/piggyBank.json';
import { EXPENSE_ICON } from 'images/AWS/Investments/index';
import { CyanButton } from '@components/Reusables/CyanButton';
import { navigate } from 'gatsby';

interface investProps {
  openMenu: () => void;
  globals: any;
}

const SavingMobile = ({ globals = null, openMenu }: investProps) => {
  const classes = useStyles();
  const { profileForm } = useContext(ProfileContext);
  const { openNoGoalWarningModal, openNoPersonalDataWarningModal } =
    useContext(AppContext);
  const { openAddFundsModal } = useContext(NewInvestContext);
  const { goals } = useGoals();
  const { user: currentUser } = useCurrentUser();
  const [isNoData, setNoData] = useState(false);
  console.log(goals);

  useEffect(() => {
    if (
      !currentUser?.isIdentityValidated ||
      !currentUser?.isPersonalDataCompleted ||
      !currentUser?.isSpecialDataCompleted
    ) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [currentUser]);

  const handleAddFundsClick = useCallback(() => {
    if (goals.length == 0) {
      openNoGoalWarningModal();
    } else {
      if (
        !currentUser?.isIdentityValidated ||
        !currentUser?.isPersonalDataCompleted ||
        !currentUser?.isSpecialDataCompleted
      ) {
        setNoData(true);
      } else {
        setNoData(false);
        openAddFundsModal(null);
      }
    }
  }, [isNoData, goals]);

  return (
    <section className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.titleCardContainer}>
          <div className={classes.piggieAndSavingsContainer}>
            <div className={classes.piggieAnimationContainer}>
              <Player src={piggy} className="player" loop autoplay speed={1} />
            </div>
            <div>
              <p className={classes.youHaveSavedText}>Haz ahorrado en total</p>
              <p className={classes.savedQuantityText}>
                $ {parseNumber(globals.currentValue)}
              </p>
            </div>
          </div>
        </div>

        <div className={classes.valuesContainer}>
          <div className={classes.containerCard}>
            <div className={classes.card}>
              <div className={classes.titleCard}>Aporte total</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.totalDeposited)}
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.titleCard}>Ganancia total</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.profits)}
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.titleCard}>Retiro total</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.totalWithdrawn)}
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.titleCard}>Caja</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.cashValue)}
              </div>
            </div>
          </div>
          <div className={classes.buttonsContainer}>
            <CyanButton
              className={classes.button}
              text="Nuevo"
              outLined
              startIcon={
                <>
                  <img src={EXPENSE_ICON} className={classes.whiteFilter} />
                </>
              }
              onClick={() => {
                openMenu();
              }}
            />
            {isNoData && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className={classes.tooltip}>
                  ¡Completa tu perfil para hacer tu primer aporte!
                </div>
                <div className={classes.triangle} />
              </>
            )}
            {!isNoData ? (
              <CyanButton
                className={classes.button}
                text="Depositar"
                startIcon={
                  <>
                    <img src={EXPENSE_ICON} className={classes.whiteFilter} />
                  </>
                }
                onClick={handleAddFundsClick}
              />
            ) : (
              <CyanButton
                className={classes.button}
                text="Completar perfil"
                startIcon={
                  <>
                    <img
                      className={`${classes.filter}`}
                      src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/user02_icon.svg"
                    />
                  </>
                }
                onClick={() => navigate(Route.UserInformation)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SavingMobile;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  tooltip: {
    background: '#008195',
    padding: '0.2rem 0.8rem',
    borderRadius: 5,
    color: 'white',
    position: 'absolute',
    bottom: '7.5rem',
    fontSize: '0.875rem',
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '10px solid #008195',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
    position: 'absolute',
    bottom: '7rem',
    right: '4rem',
  },
  cardContainer: {
    width: '100%',
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 30,
    marginTop: '1rem',
  },
  titleCardContainer: {
    padding: '0.6rem 2rem',
    width: '100%',
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '2.25rem',
    fontWeight: 'bold',
    padding: 10,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1280)]: {
      fontSize: '1.5rem',
    },
  },
  txtSecondary: {
    color: theme.palette.text.secondary,
    fontSize: '1.25rem',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    paddingLeft: 10,
    display: 'block',
    [theme.breakpoints.down(1280)]: {
      fontSize: '0.75rem',
    },
  },
  valuesContainer: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '3rem',
  },
  imageCard: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      color: 'white',
      marginLeft: '1rem',
    },
    '& img': {
      width: 460,
      [theme.breakpoints.down(1280)]: {
        width: '14.05rem',
      },
    },
  },
  imageCardTitle: {
    position: 'absolute',
    top: 80,
    left: 103,
    fontSize: 40.4,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
    [theme.breakpoints.down(1280)]: {
      fontSize: '1.25rem',
      left: 90,
      top: 40,
    },
  },
  imageCardValue: {
    position: 'absolute',
    top: 130,
    left: 80,
    fontSize: 60,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
    [theme.breakpoints.down(1280)]: {
      fontSize: '1.5rem',
      left: 65,
      top: 70,
    },
    '& span': {
      fontSize: '1rem',
    },
  },
  imageCardName: {
    position: 'absolute',
    top: 235,
    left: 80,
    fontWeight: 200,
    fontSize: 25.2,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
    [theme.breakpoints.down(1280)]: {
      fontSize: 16,
      left: 80,
      top: 105,
    },
  },
  card: {
    display: 'grid',
    border: `0.5px solid rgba(255,255,255, 0.5)`,
    borderRadius: '8px',
    padding: '0.3rem',
    height: '3.125rem',
    width: '8.5rem',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  titleCard: {
    fontFamily: ' Nunito',
    fontSize: '0.75rem',
    color: 'rgba(255,255,255, 0.7)',
    fontWeight: 'normal',
  },
  valueCard: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '& span': {
      fontSize: '0.875rem',
      paddingRight: 2,
    },
  },
  button: {
    '& img': {
      height: '1rem',
    },
  },
  button2: {
    borderRadius: '10px',
    border: 'solid 1px #008296',
    boxShadow: '1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)',
    padding: '0.2rem 0.4rem',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '2rem',
    alignItems: 'center',
    width: '70%',
    height: '2.5rem',
    color: '#0a5669',
    cursor: 'pointer',
    margin: '1.5rem 0 0 16%',
    '& span': {
      display: 'block',
      paddingLeft: 5,
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.9rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      margin: '0 0.5rem 0 0',
    },
  },
  containerCard: {
    width: 360,
    display: 'grid',
    margin: '0 auto',
    justifyContent: 'center',
    gridTemplateColumns: '40% 40%',
    marginTop: '1.3rem',
    paddingBottom: '0.7rem',
    position: 'relative',
  },
  viewCaja: {
    color: theme.palette.text.disabled,
    display: 'flex',
    bottom: 10,
    left: 20,
    fontWeight: 300,
    gap: 5,
    margin: '2.8rem 0 0',
    justifyContent: 'center',
    fontSize: '1.5rem',
    alignItems: 'center',
    '& img': {
      width: 40,
    },
  },
  addIcon: {
    width: '2.5rem',
    height: '2.5rem',
  },
  piggieAndSavingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#FFFFFF',
    gap: '1rem',
  },
  piggieAnimationContainer: {
    width: '3rem',
  },
  filter: {
    height: '1rem',
  },
  youHaveSavedText: {
    fontFamily: 'Nunito',
    fontSize: '0.875rem',
    opacity: 0.7,
  },
  savedQuantityText: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.56rem',
    '& button': {
      minWidth: '170px',
    },
  },
  cyanFilter: {
    filter:
      'invert(76%) sepia(53%) saturate(1745%) hue-rotate(163deg) brightness(108%) contrast(101%)',
  },
  whiteFilter: {
    height: '1rem',
    filter:
      ' invert(100%) sepia(0%) saturate(7495%) hue-rotate(216deg) brightness(98%) contrast(107%)',
  },
}));
